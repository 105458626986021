import AnalyticsHome from 'src/views/waterquality';
import AltDashboard from 'src/views/altDashboard';
import DashboardView from 'src/views/reports/DashboardView/index1';

// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const API_BFD_V2_ROOT = process.env.NODE_ENV === 'production' ? 'https://bfd-api-v2-main.azurewebsites.net/api/' : 'http://localhost:8080/api/';
export const API_ROOT = process.env.NODE_ENV !== 'production' ? 'http://localhost:8080/api/' : 'https://api.esassoc.net/api/';
export const ANA_API = process.env.NODE_ENV !== 'production' ? 'https://localhost:44351/api/' : 'https://api.esassoc.net/api/';
export const APP_VERSION = '0.1';
export const PHOTO_BASE = `${API_ROOT}FulcrumPhotos/`;
export const API_BASE_URL = `${API_ROOT}FulcrumData/`;

export const COLORS = ['#4287f5', '#76d938', '#d15434', '#d1cc2e', '#31d4bb', '#ad31cc', '#037329', '#6e4803',
  '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8', '#648177', '#0d5ac1',
  '#f205e6', '#1c0365', '#14a9ad', '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0',
  '#d2737d', '#c0a43c', '#f2510e', '#651be6', '#79806e', '#61da5e', '#cd2f00',
  '#9348af', '#01ac53', '#c5a4fb', '#996635', '#b11573', '#4bb473', '#75d89e',
  '#2f3f94', '#2f7b99', '#da967d', '#34891f', '#b0d87b', '#ca4751', '#7e50a8',
  '#c4d647', '#e0eeb8', '#11dec1', '#289812', '#566ca0', '#ffdbe1', '#2f1179',
  '#935b6d', '#916988', '#513d98', '#aead3a', '#9e6d71', '#4b5bdc', '#0cd36d',
  '#250662', '#cb5bea', '#228916', '#ac3e1b', '#df514a', '#539397', '#880977',
  '#f697c1', '#ba96ce', '#679c9d', '#c6c42c', '#5d2c52', '#48b41b', '#e1cf3b',
  '#5be4f0', '#57c4d8', '#a4d17a', '#225b8', '#be608b', '#96b00c', '#088baf',
  '#f158bf', '#e145ba', '#ee91e3', '#05d371', '#5426e0', '#4834d0', '#802234',
  '#6749e8', '#0971f0', '#8fb413', '#b2b4f0', '#c3c89d', '#c9a941', '#41d158',
  '#fb21a3', '#51aed9', '#5bb32d', '#807fb', '#21538e', '#89d534', '#d36647',
  '#7fb411', '#0023b8', '#3b8c2a', '#986b53', '#f50422', '#983f7a', '#ea24a3',
  '#79352c', '#521250', '#c79ed2', '#d6dd92', '#e33e52', '#b2be57', '#fa06ec',
  '#1bb699', '#6b2e5f', '#64820f', '#1c271', '#21538e', '#89d534', '#d36647',
  '#7fb411', '#0023b8', '#3b8c2a', '#986b53', '#f50422', '#983f7a', '#ea24a3',
  '#79352c', '#521250', '#c79ed2', '#d6dd92', '#e33e52', '#b2be57', '#fa06ec',
  '#1bb699', '#6b2e5f', '#64820f', '#1c271', '#9cb64a', '#996c48', '#9ab9b7',
  '#06e052', '#e3a481', '#0eb621', '#fc458e', '#b2db15', '#aa226d', '#792ed8',
  '#73872a', '#520d3a', '#cefcb8', '#a5b3d9', '#7d1d85', '#c4fd57', '#f1ae16',
  '#8fe22a', '#ef6e3c', '#243eeb', '#1dc18', '#dd93fd', '#3f8473', '#e7dbce',
  '#421f79', '#7a3d93', '#635f6d', '#93f2d7', '#9b5c2a', '#15b9ee', '#0f5997',
  '#409188', '#911e20', '#1350ce', '#10e5b1', '#fff4d7', '#cb2582', '#ce00be',
  '#32d5d6', '#17232', '#608572', '#c79bc2', '#00f87c', '#77772a', '#6995ba',
  '#fc6b57', '#f07815', '#8fd883', '#060e27', '#96e591', '#21d52e', '#d00043',
  '#b47162', '#1ec227', '#4f0f6f', '#1d1d58', '#947002', '#bde052', '#e08c56',
  '#28fcfd', '#bb09b', '#36486a', '#d02e29', '#1ae6db', '#3e464c', '#a84a8f',
  '#911e7e', '#3f16d9', '#0f525f', '#ac7c0a', '#b4c086', '#c9d730', '#30cc49',
  '#3d6751', '#fb4c03', '#640fc1', '#62c03e', '#d3493a', '#88aa0b', '#406df9',
  '#615af0', '#4be47', '#2a3434', '#4a543f', '#79bca0', '#a8b8d4', '#00efd4',
  '#7ad236', '#7260d8', '#1deaa7', '#06f43a', '#823c59', '#e3d94c', '#dc1c06',
  '#f53b2a', '#b46238', '#2dfff6', '#a82b89', '#1a8011', '#436a9f', '#1a806a',
  '#4cf09d', '#c188a2', '#67eb4b', '#b308d3', '#fc7e41', '#af3101', '#ff065',
  '#71b1f4', '#a2f8a5', '#e23dd0', '#d3486d', '#00f7f9', '#474893', '#3cec35',
  '#1c65cb', '#5d1d0c', '#2d7d2a', '#ff3420', '#5cdd87', '#a259a4', '#e4ac44',
  '#1bede6', '#8798a4', '#d7790f', '#b2c24f', '#de73c2', '#d70a9c', '#25b67',
  '#88e9b8', '#c2b0e2', '#86e98f', '#ae90e2', '#1a806b', '#436a9e', '#0ec0ff',
  '#f812b3', '#b17fc9', '#8d6c2f', '#d3277a', '#2ca1ae', '#9685eb', '#8a96c6',
  '#dba2e6', '#76fc1b', '#608fa4', '#20f6ba', '#07d7f6', '#dce77a', '#77ecca'];// ['#4287f5','#76d938','#d15434','#d1cc2e','#31d4bb','#ad31cc','#037329','#6e4803']
export const ENABLE_REDUX_LOGGER = false;
export const DEF_MAP_COORDS = { lat: 38.520, lng: -121.49 };
export const PROJECT_BASE = 'LEBLS';
export const ALT_PROJECT_BASE = `${PROJECT_BASE} Monitoring`;
export const RPT_BASE_LINK = `${API_ROOT}Reports/${PROJECT_BASE}Main?id=`;
export const REPLY_URL = '';
export const CurrentProject = 'SDDR';
export const MAX_COMM_DEF_LENGTH = 75;
export const MAX_REPORT_EXPORT = 35;
// export const GEN_API_URL = process.env.NODE_ENV === 'production' ? 'https://tbw-wq-api.azurewebsites.net' : 'https://tbw-wq-api.azurewebsites.net'
export const GEN_API_URL = process.env.NODE_ENV === 'production' ? 'https://tbw-wq-api.azurewebsites.net' : 'http://localhost:8080';
export const WEATHER_API_KEY = '34f2a6bcecfced7fd2bd2a49a97105bc';
export const ROLES_HOME = [

  {
    role: 'wq-dash',
    homePage: 'src/views/waterquality/index',
    redir: 'app/analytics/waterquality/all',
    component: AnalyticsHome
  },
  {
    role: 'fdc-dash-alt',
    homePage: 'src/views/altDashboard/index',
    component: AltDashboard

  }, {
    role: 'fdc-dash',
    homePage: 'src/views/reports/DashboardView/index1',
    component: DashboardView
  }

];
export const appId = 'f706e3c3-e9e7-47ed-928c-76da601bc975';
export const roles = {
  // Azure Security Groups
  // Admins 	a5fef2f3-f6f2-4205-b7a9-d26ff7314c07
  // Reg users App-Field-Forms-User /d5850d61-4550-4b40-a204-37a535fd37da
  // Guests, neither
  admin: 'a5fef2f3-f6f2-4205-b7a9-d26ff7314c07',
  user: '755bc407-a1fb-4fd6-a617-c10e109b9378'
};
export const scopes = [
  'user.read'
];
/* QUERIES */
export function Queries(name, project) {
  project = project || PROJECT_BASE;

  const QUERIES = {
    ReportItem: `SELECT * FROM [${ALT_PROJECT_BASE}] WHERE _record_id='@ID@'`,
    Observations: `SELECT * from [${ALT_PROJECT_BASE}!1000!observations] WHERE parent_id = '@ID@'`,
    Observations2: `SELECT * from [${PROJECT_BASE} Observations] where _record_id in (SELECT linked_record_id from [${ALT_PROJECT_BASE}!1000!observations] WHERE source_record_id = '@ID@') ORDER BY  time_of_observation `,
    Observation: `SELECT * from [${ALT_PROJECT_BASE}!1000!observations] WHERE _record_id = '@ID@'`,
    Trackables: `SELECT * from [${ALT_PROJECT_BASE} Obervations] where _record_id in (SELECT _linked_record_id from [${PROJECT_BASE} Monitoring!1000!observation] where source_record_id ='@ID') AND   _status <> 'Archive' `,
    Logs: `Select CONVERT(date, date) as justdate, * from [${PROJECT_BASE} Observation Log] where _record_id in (SELECT linked_record_id from [${PROJECT_BASE} Observations!1000!observation_entries] where source_record_id='@ID@')  and  CONVERT(date, date) = CONVERT(date,'@date@') order by [time]`,
    DailyReports: `SELECT *, CONVERT(date, date_general) as justdate  from [${PROJECT_BASE} Monitoring] where _status <> 'Archive' order by  date_general  desc `,
    DashChart: `SELECT COUNT(*) as c, date_of_observation as lbl from [${PROJECT_BASE} Observations] where date_of_observation > '1-1-2020' group by date_of_observation`,
    Communication: `SELECT contacted,field_person, discussed, [date], [time] from [vw${PROJECT_BASE}Coms] where mon_id='@ID@' order by [date] desc`,
    // TopCommunication: `SELECT * from [${PROJECT_BASE} Communication] where [date]>='@startDate@' AND [date]<='@endDate@' @ADDFILTER@ order by [date] desc`,
    TopCommunication: `SELECT field_person, contacted, discussed,mon_id as _record_id, [date], [time] from [vw${PROJECT_BASE}Coms] where [date]>='@startDate@' AND [date]<='@endDate@' @ADDFILTER@ order by [date] desc`,
    SingleObs: `SELECT * FROM [${PROJECT_BASE} Observations] where _record_id ='@ID@'`,
    // OBSCOMS : "SELECT CONVERT(date, date) as justdate, * FROM [vw" + PROJECT_BASE + "Coms] where obs_id =  '@ID@' and  CONVERT(date, date) ='@date@'",
    OBSCOMS: `SELECT format(CONVERT(date, date),'MM-dd-yyyy') as justdate , * FROM [vw${PROJECT_BASE}MonComs] where ObsId =  '@ID@' and  CONVERT(date, date) ='@date@'`,
    // LOGGEN2: ` SELECT format(CONVERT(date, [date]),'MM-dd-yyyy') as justdate,* from [vw${PROJECT_BASE}ObsLogs] WHERE ObsId='@ID@' ORDER BY  date DESC,  time DESC`, // TODO: wire this up, ==> orginal query
    LOGGEN2: ` SELECT format(CONVERT(date, [date]),'MM-dd-yyyy') as justdate, * from [vw${PROJECT_BASE}ObsLogs] WHERE ObsId='@ID@' ORDER BY date DESC,  time DESC`,

    LOGGEN: `SELECT * FROM vw${PROJECT_BASE}ObservationLogs where Observation_id='@ID@'`,
    // OBSCOMSND: `SELECT format(CONVERT(date, [date]),'MM-dd-yyyy') as justdate, * FROM [vw${PROJECT_BASE}MonComs] where ObsId =  '@ID@' `,  // TODO: wire this up, ==> orginal query
    OBSCOMSND: `SELECT dbo.[${PROJECT_BASE} Communication].*, dbo.[${PROJECT_BASE} Observations]._record_id AS ObsId FROM dbo.[${PROJECT_BASE} Observations/communication_for_observation] INNER JOIN
                         dbo.[${PROJECT_BASE} Observations] ON dbo.[${PROJECT_BASE} Observations/communication_for_observation].source_record_id = dbo.[${PROJECT_BASE} Observations]._record_id RIGHT OUTER JOIN
                         dbo.[${PROJECT_BASE} Communication] ON dbo.[${PROJECT_BASE} Observations/communication_for_observation].linked_record_id = dbo.[${PROJECT_BASE} Communication]._record_id
                         where dbo.[${PROJECT_BASE} Observations]._record_id = '@ID@'`,

    ReportLocations: `SELECT   dbo.[${PROJECT_BASE} Monitoring]._record_id AS mon_record_id, dbo.[${PROJECT_BASE} Observations]._record_id AS obs_record_id, dbo.[${PROJECT_BASE} Observations].location_of_observation  FROM  dbo.[${PROJECT_BASE} Monitoring/observations] INNER JOIN dbo.[${PROJECT_BASE} Observations] ON dbo.[${PROJECT_BASE} Monitoring/observations].linked_record_id = dbo.[${PROJECT_BASE} Observations]._record_id INNER JOIN   dbo.[${PROJECT_BASE} Monitoring] ON dbo.[${PROJECT_BASE} Monitoring/observations].source_record_id = dbo.[${PROJECT_BASE} Monitoring]._record_id`,

    ALT_Paleo:
      "SELECT _record_id as id, boring_label, field_person, date, fossils_found  from [vwGeotechReports] where discipline = 'Paleontological' order by date desc",
    ALT_Main:
      'SELECT _child_record_id as id,_record_id as linkid, date, field_person,boring_label,curLat as _latitude, curLng as _longitude,terminal_depth_feet, discipline,boringId, relocate,og_y_coords,og_x_coords,distance_from_original_location_feet from vwGeotechReports ',
    ALT_Coms:
      'Select *, summary_of_discussion as  discussed from vwGeoTechComs',
    ALT_SR:
      'SELECT _child_record_id as id, sp_of_interest,_latitude,_longitude,* from [vwGeoTechSr] order by [date] desc',
    ALT_SoloReport:
      "SELECT * from [vwGeotechReports] where boringId = '@recid@'",
    ALT_SR_RPT:
      "SELECT sp_of_interest,_latitude,_longitude,* from [vwGeoTechSr] where _parent_id='@recid'",
    ALT_FOSSILS_RPT:
      "SELECT  * from [Subsidence Geotech Explorations!1000!fossil_collection_log] where _parent_id='@recid'",
    ALT_FOSSILS:
      'SELECT _child_record_id as id, * FROM vwGeoTechFossils order by [date] desc',
    Observations_ReportID: `SELECT   dbo.[${project} Monitoring]._record_id AS mon_record_id, dbo.[${project} Observations]._record_id AS obs_record_id, dbo.[${project} Observations].location_of_observation  FROM  dbo.[${project} Monitoring/observations] INNER JOIN dbo.[${project} Observations] ON dbo.[${project} Monitoring/observations].linked_record_id = dbo.[${project} Observations]._record_id INNER JOIN   dbo.[${project} Monitoring] ON dbo.[${project} Monitoring/observations].source_record_id = dbo.[${project} Monitoring]._record_id  where  dbo.[${project} Observations]._record_id = `,
    ObservationLogs_ReportID: `SELECT dbo.[${project} Monitoring]._record_id AS mon_record_id, dbo.[${project} Observations]._record_id AS obs_record_id, dbo.[${project} Observation Log]._record_id AS log_record_id FROM dbo.[${project} Monitoring/observations] INNER JOIN dbo.[${project} Observations] ON dbo.[${project} Monitoring/observations].linked_record_id = dbo.[${project} Observations]._record_id INNER JOIN dbo.[${project} Monitoring] ON dbo.[${project} Monitoring/observations].source_record_id = dbo.[${project} Monitoring]._record_id INNER JOIN dbo.[${project} Observations/observation_entries] ON dbo.[${project} Observations]._record_id = dbo.[${project} Observations/observation_entries].source_record_id INNER JOIN dbo.[${project} Observation Log] ON dbo.[${project} Observations/observation_entries].linked_record_id = dbo.[${project} Observation Log]._record_id where dbo.[${project} Observation Log]._record_id= `,
    Communications_ReportID: `SELECT dbo.[${project} Monitoring]._record_id AS mon_record_id, dbo.[${project} Communication]._record_id AS com_record_id FROM dbo.[${project} Monitoring/observations] INNER JOIN dbo.[${project} Monitoring] ON dbo.[${project} Monitoring/observations].source_record_id = dbo.[${project} Monitoring]._record_id INNER JOIN dbo.[${project} Observations] INNER JOIN dbo.[${project} Observations/communication_for_observation] ON dbo.[${project} Observations]._record_id = dbo.[${project} Observations/communication_for_observation].source_record_id INNER JOIN dbo.[${project} Communication] ON dbo.[${project} Observations/communication_for_observation].linked_record_id = dbo.[${project} Communication]._record_id ON dbo.[${project} Monitoring/observations].linked_record_id = dbo.[${project} Observations]._record_id where dbo.[${project} Communication]._record_id =`,
    TrackablesDashboard: `SELECT *, DATEADD(hour, -8,_updated_at) as _updated from [${project} Observations] where follow_up_yes_no = 'yes' AND _status <> 'Archive'`
  };

  return (
    QUERIES[name]
  );
}
/* export const QUERIES = {
    ReportItem : "SELECT * FROM [" + ALT_PROJECT_BASE + "] WHERE _record_id='@ID@'",
    Observations : "SELECT * from [" + ALT_PROJECT_BASE + "!1000!observations] WHERE parent_id = '@ID@'",
    Observations2 : "SELECT * from [" +PROJECT_BASE + " Observations] where _record_id in (SELECT linked_record_id from [" + ALT_PROJECT_BASE + "!1000!observations] WHERE source_record_id = '@ID@') ",
    Observation : "SELECT * from [" + ALT_PROJECT_BASE + "!1000!observations] WHERE _record_id = '@ID@'",
    Trackables : "SELECT * from [" +ALT_PROJECT_BASE + " Obervations] where _record_id in (SELECT _linked_record_id from [" +project + " Monitoring!1000!observation] where source_record_id ='@ID') ",
    Logs : "Select CONVERT(date, date) as justdate, * from [" +PROJECT_BASE + " Observation Log] where _record_id in (SELECT linked_record_id from [" + PROJECT_BASE + " Observations!1000!observation_entries] where source_record_id='@ID@') order by [time]",
    DailyReports: "SELECT *, CONVERT(date, date_general) as justdate  from [" + PROJECT_BASE + " Monitoring] order by CONVERT(date, date_general) desc ",
    DashChart : "SELECT COUNT(*) as c, date_of_observation as lbl from [" + PROJECT_BASE + " Observations] where date_of_observation > '1-1-2020' group by date_of_observation",
    Communication : "SELECT contacted,field_person, discussed, [date], [time] from [vw" + PROJECT_BASE + "Coms] where [date]>='@startDate@' AND [date]<='@endDate@' order by [date] desc",
    TopCommunication : "SELECT field_person, contacted, discussed, [date], [time] from [vw" + PROJECT_BASE + "Coms] where [date]>='@startDate@' AND [date]<='@endDate@' @ADDFILTER@ order by [date] desc",
    SingleObs : "SELECT * FROM [" + PROJECT_BASE + " Observations] where _record_id ='@ID@'",
    OBSCOMS : "SELECT CONVERT(date, date) as justdate, * FROM [vw" + PROJECT_BASE + "Coms] where obs_id =  '@ID@'",
    LOGGEN : "SELECT * FROM vw" + PROJECT_BASE + "ObservationLogs where Observation_id='@ID@'"

} */

// Built on https://material-ui.com/store/items/devias-kit-pro
/* Daily Report Fields */
export const MATERIAL_GRID_LIC = '514a76d5aa44bb0035380966f28502feTz0xMDE0NTYsRT0xNzYyNjM0MDI4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
export const DisplayFields = {
  brief: ['']
};

export default {};
